import { useCallback, useEffect, useRef, useState } from "react"
import { useElements, useStripe } from "@stripe/react-stripe-js"
import { captureMessage } from "@sentry/react"
import parse from "html-react-parser"

import { post } from "shared/lib/api"
import { rejectedXHRPromiseLogger } from "shared/lib/xhr_promise"

import classNames from "classnames"
import InlineErrors from "shared/components/inline_errors"
import Tooltip from "shared/components/tooltip"
import { gtag } from "shared/lib/utils"

import CreditCardField from "shared/components/credit_card_field"
import SubscriptionHelper from "./subscription_helper"
import PromoCodeForm from "./promo_code_form.js"

import scheduleAbandonedCheckoutEmail from "./abandoned_checkout_mail_helper"

import googlePayLogo from "images/shared/google_pay_logo.svg"
import applePayLogo from "images/shared/apple_pay_logo.svg"

import "./payment_info_form.scss"

interface URLs {
  createStripeCustomerUrl: string
  subscriptionsPath: string
  subscriptionSuccessPath: string
}

interface PlanType {
  name: string
  amount: number
  interval: "month" | "year"
  local_price_formatted: string
  local_price_formatted_monthly: string
  local_price_monthly: number
  currency: string
}

interface Props {
  browserType: string
  eligibleForTrial: boolean
  email: string
  initialPromoCode: string | null
  modalButtonType: string
  monthlyPlan: PlanType
  onModalHandleSuccessfulSubscription: () => void
  planId: string
  productId: string
  redirectToMobileAppOnSuccess: boolean
  referrerPath: string
  renderingInsideModal: boolean
  setPermissions?: (subscription: object) => void
  setSubscription?: (subscription: object) => void
  studentProgramCheckout?: boolean
  tier: string
  urls: URLs
  yearlyPlan: PlanType
  initialInterval?: "month" | "year"
}

export default function PaymentInfoForm({
  browserType,
  eligibleForTrial,
  email,
  initialPromoCode,
  modalButtonType,
  monthlyPlan,
  onModalHandleSuccessfulSubscription,
  planId,
  productId,
  redirectToMobileAppOnSuccess,
  referrerPath,
  renderingInsideModal,
  setPermissions,
  setSubscription,
  studentProgramCheckout,
  tier,
  urls,
  yearlyPlan,
  initialInterval,
}: Props) {
  const isSafari = browserType === "safari"
  const isChrome = browserType === "chrome"

  const stripe = useStripe()
  const elements = useElements()
  const [paymentRequest, setPaymentRequest] = useState(null)
  const [loadingPaymentRequest, setLoadingPaymentRequest] = useState(true)
  const [paymentRequestActive, setPaymentRequestActive] = useState(true)

  const cardRef = useRef(null)
  const formRef = useRef(null)
  const [handlingSubmit, setHandlingSubmit] = useState(false)
  const [cardReady, setCardReady] = useState(false)
  const [promoCode, setPromoCode] = useState(null)
  const [promoPriceDetails, setPromoPriceDetails] = useState(null)
  const initialPlan = initialInterval === "month" ? monthlyPlan : yearlyPlan
  const [plan, setPlan] = useState(initialPlan)

  const planName = "Pro"
  const priceTypeMonthly = "monthly"
  const priceTypeYearly = "yearly"
  const [selectedPrice, setSelectedPrice] = useState(initialPlan.amount)
  const [validationErrors, setValidationErrors] = useState(null)
  const discountForYearly = Math.round(((monthlyPlan.amount - yearlyPlan.amount / 12) / monthlyPlan.amount) * 100)

  const inlineErrorFields = ["price"]
  const defaultErrorMessagesForField = {
    price: ["Choose a price between $1.99 USD and $100 USD"],
  }

  useEffect(() => {
    // schedule the abandoned checkout worker once the page is viewed since
    // it can only be sent once, to non-pros
    scheduleAbandonedCheckoutEmail(tier)

    post(urls.createStripeCustomerUrl)
      .then(data => {
        const { errors } = data
        if (errors && errors.length > 0 && window.App.allowDebugErrorMessages) {
          captureMessage("createStripeCustomer failed", { extra: errors })
        }
      })
      .catch(rejectedXHRPromiseLogger())
  }, [])

  useEffect(() => {
    const price = getPrice()

    if (stripe) {
      if (!paymentRequest) {
        const pr = stripe.paymentRequest({
          country: "US",
          currency: price?.currency?.toLowerCase() || "usd",
          total: {
            label: planName,
            amount: selectedPrice,
            pending: true,
          },
          requestPayerName: true,
          requestPayerEmail: true,
        })

        pr.canMakePayment().then(result => {
          setLoadingPaymentRequest(false)

          if (result) {
            window.App.Utils?.logCustomDatadogAction("stripe_checkout_payment_request_available")
            setPaymentRequest(pr)
          } else {
            setTimeout(() => {
              setPaymentRequestActive(false)
            }, 1000)
          }
        })
      }
    }
  }, [stripe, paymentRequest])

  const setupPaymentMethodHandler = useCallback(
    (price: object) => {
      if (!paymentRequest) return

      window.App.Utils?.logCustomDatadogAction("stripe_checkout_start")

      paymentRequest.on("cancel", async () => {
        setHandlingSubmit(false)
        window.App.Utils?.logCustomDatadogAction("stripe_checkout_cancel")
      })

      // this creates a closure, so make sure to do it when the price is fixed
      paymentRequest.on("paymentmethod", async ev => {
        if (handlingSubmit) {
          return
        }
        setHandlingSubmit(true)

        await SubscriptionHelper({
          stripe,
          subscriptionsPath: urls.subscriptionsPath,
          price,
        }).subscribe(
          planId,
          productId,
          ev.paymentMethod,
          promoCode,
          (subscription: object, permissions: object) => {
            ev.complete("success")
            handleSuccessfulSubscription(subscription, permissions, planId, promoCode)
          },
          (error: object) => {
            ev.complete("fail")
            setPaymentRequest(null)
            handleError(error)
          }
        )
      })
    },
    [paymentRequest]
  )

  useEffect(() => {
    if (referrerPath && !referrerPath.match(/(user\/new|onboarding.*)/)) {
      sessionStorage.setItem("afterProPaymentPath", referrerPath)
    }

    if (studentProgramCheckout) handlePriceTypeChange(priceTypeYearly)
  }, [])

  function handlePaymentRequestButtonClick(event) {
    event.preventDefault()
    if (handlingSubmit) {
      return
    }
    setHandlingSubmit(true)

    const price = getPrice()

    paymentRequest?.update({
      total: {
        label: planName,
        amount: eligibleForTrial ? 0 : price.price_in_cents,
        currency: price.currency || "usd",
      },
    })
    paymentRequest?.show()
    setupPaymentMethodHandler(price)
  }

  async function handleSubmit(event: MouseEvent) {
    if (handlingSubmit) {
      return
    }
    setHandlingSubmit(true)

    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded. Make sure to disable
      // form submission until Stripe.js has loaded.
      setHandlingSubmit(false)
      return
    }

    window.App.Utils?.logCustomDatadogAction("stripe_checkout_start")

    await SubscriptionHelper({
      stripe,
      subscriptionsPath: urls.subscriptionsPath,
      price: getPrice(),
    }).subscribe(
      planId,
      productId,
      {
        card: elements.getElement(cardRef.current.getElement()),
        billing_details: { email },
      },
      promoCode,
      handleSuccessfulSubscription,
      handleError
    )
  }

  function onPageHandleSuccessfulSubscription(subscription: object, permissions: object) {
    let successUrl = urls.subscriptionSuccessPath + subscription.subscription_id
    if (redirectToMobileAppOnSuccess) {
      successUrl += "?mobile=1"
    }

    window.history.pushState({}, "Success! You now have access to IFTTT Pro", successUrl)

    setPermissions(permissions)
    setSubscription(subscription)
  }

  function handleSuccessfulSubscription(
    subscription: object,
    permissions: object,
    planId: string,
    promoCode: string | null
  ) {
    try {
      window.App.Utils?.logCustomDatadogAction("stripe_checkout_success")

      // This event will fire 2 tags on Tag Manager: custom event and a purchase event. Populating datalayer with all necessary data.
      gtag("TM_payment_complete", {
        currency: plan.currency,
        eligible_for_trial: eligibleForTrial,
        plan_id: planId,
        plan_interval: subscription.payment_interval,
        price_in_cents: subscription.price_in_cents,
        promo_code: promoCode,
        subscription_id: subscription.subscription_id,
        items: [
          {
            item_id: subscription.product_id,
            item_name: planId,
            item_variant: subscription.payment_interval,
            eligible_for_trial: eligibleForTrial,
          },
        ],
      })
    } catch (e) {
      if (window.App.env && window.App.allowDebugErrorMessages) console.error(e)
    } finally {
      renderingInsideModal
        ? onModalHandleSuccessfulSubscription()
        : onPageHandleSuccessfulSubscription(subscription, permissions)
    }
  }

  function handleError(error) {
    setHandlingSubmit(false)
    const errorMessage = error.message || error

    if (error.attribute === "promo_code") {
      setPromoCode(null)
    }

    window.App.Utils?.logCustomDatadogAction("stripe_checkout_failure", { error: errorMessage })

    if (!inlineErrorFields.includes(error.attribute)) {
      window.appendFlash(errorMessage, "danger")
      return
    }

    const errors = {}
    const defaultMessages = defaultErrorMessagesForField[error.attribute]
    errors[error.attribute] = defaultMessages || [errorMessage]
    setValidationErrors(errors)
    InlineErrors.scrollWithDelay()
  }

  function handlePriceTypeChange(priceType: string) {
    const plan = priceType === priceTypeYearly ? yearlyPlan : monthlyPlan
    setValidationErrors(null)
    setPlan(plan)
    setSelectedPrice(plan.amount)
  }

  function openZendesk(e) {
    e.preventDefault()
    window.App.loadZendeskWidget()
  }

  function getPrice() {
    return {
      price_type: selectedPrice === yearlyPlan.amount ? priceTypeYearly : priceTypeMonthly,
      price_in_cents: selectedPrice,
      currency: plan.currency,
    }
  }

  function submitButtonDisabled() {
    const formReady = cardReady && !handlingSubmit && !validationErrors
    return !formReady
  }

  let buttonTitle = "Upgrade"
  let submittingCopy = "Upgrading..."

  if (eligibleForTrial && !studentProgramCheckout) {
    buttonTitle = "Try it free"
    submittingCopy = "Submitting..."
  }

  const promoCodeApplied = promoCode && promoPriceDetails

  return (
    <form ref={formRef} styleName="payment-info-form" className="form payment-info-form" onSubmit={handleSubmit}>
      <div styleName="stripe-payment-container">
        <section styleName="price-container">
          {studentProgramCheckout ? (
            <div styleName="price-annual-option">
              <span styleName="title">Get started today</span>
              <span styleName="price-value">{yearlyPlan.local_price_formatted_monthly}/month</span>
              <span styleName="price-period">{`Billed at ${yearlyPlan.local_price_formatted} per year`}</span>
            </div>
          ) : (
            <>
              <div styleName="price-list">
                <div
                  styleName={classNames({
                    "selected-price": plan.interval === "year",
                  })}
                  role="button"
                  onClick={() => handlePriceTypeChange(priceTypeYearly)}
                >
                  {promoCodeApplied ? (
                    <>
                      <span styleName="price-value full-price">{yearlyPlan.local_price_formatted_monthly}</span>
                      <span styleName="price-value">{promoPriceDetails.year_price_per_month}</span>
                    </>
                  ) : (
                    <span styleName="price-value">{yearlyPlan.local_price_formatted_monthly}</span>
                  )}
                  <span styleName="price-period">/month</span>
                  <span styleName={classNames("price-discount", { hidden: promoCodeApplied })}>
                    SAVE {discountForYearly}%
                  </span>
                </div>
                <div
                  styleName={classNames({
                    "selected-price": plan.interval === "month",
                  })}
                  role="button"
                  onClick={() => handlePriceTypeChange(priceTypeMonthly)}
                >
                  {promoCodeApplied ? (
                    <>
                      <span styleName="price-value full-price">{monthlyPlan.local_price_formatted}</span>
                      <span styleName="price-value">{promoPriceDetails.month_price}</span>
                    </>
                  ) : (
                    <span styleName="price-value">{monthlyPlan.local_price_formatted}</span>
                  )}
                  <span styleName="price-period">/month</span>
                </div>
              </div>
              <div styleName="price-interval" className="mt1">
                <div styleName={classNames({ "selected-interval": plan.interval === "year" })}>
                  {promoCodeApplied ? (
                    <>
                      <span styleName="full-price">{yearlyPlan.local_price_formatted} billed annually</span><br/>
                      {promoPriceDetails.year_price} billed annually
                    </>
                  ) : (
                    `${yearlyPlan.local_price_formatted} billed annually`
                  )}
                </div>
                <div styleName={classNames({ "selected-interval": plan.interval === "month" })}>Billed monthly</div>
              </div>
            </>
          )}
        </section>
        <section className="mt4">
          <div styleName={classNames("card-info", {inactive: !paymentRequestActive})}>
            <div styleName="payment-request-container">
              <button
                onClick={handlePaymentRequestButtonClick}
                styleName={classNames("payment-request", { loading: loadingPaymentRequest, inactive: !paymentRequest})}
                data-dd-action-name="Subscription wallet payment button"
              >
                <div styleName="unavailable">
                  {isSafari && "Apple Pay not available"}
                  {isChrome && "GPay not available"}
                  {!isSafari && !isChrome && "Payment method not available"}
                </div>
                {paymentRequest && (
                  <>
                    {isSafari && (
                      <div>{parse(applePayLogo)}</div>
                    )}
                    {isChrome && (
                      <div>{parse(googlePayLogo)}</div>
                    )}
                    {!(isSafari || isChrome) && "Pay Now"}
                  </>
                )}
              </button>
            </div>
            <div styleName="payment-request-separator">
              <div styleName="rule" />
              <div styleName="copy">or pay with card</div>
              <div styleName="rule" />
            </div>
          </div>

          <CreditCardField ref={cardRef} onCardReadyChange={setCardReady} />

          <PromoCodeForm
            initialPromoCode={initialPromoCode}
            onValidCodeApplied={setPromoCode}
            plans={{
              currency: plan.currency,
              monthlyPriceInCents: monthlyPlan.amount,
              selectedInterval: plan.interval,
              yearlyPriceInCents: yearlyPlan.amount,
            }}
            promoPriceDetails={promoPriceDetails}
            setPromoPriceDetails={setPromoPriceDetails}
          />
        </section>
      </div>
      <section styleName="action-buttons" className="mt4 mt2--phone">
        <button
          id="submit-payment"
          type="submit"
          disabled={handlingSubmit}
          className={modalButtonType ? "button-secondary" : "button-primary full-width"}
          title={buttonTitle}
          aria-label={buttonTitle}
          data-dd-action-name="Subscription credit card payment button"
        >
          {handlingSubmit ? submittingCopy : buttonTitle}
          {submitButtonDisabled() && !handlingSubmit && (
            <Tooltip>
              Please be sure you’ve correctly completed the form. <br />
              <a className="link-inline" onClick={openZendesk}>
                Contact us
              </a>
              for help.
            </Tooltip>
          )}
        </button>
      </section>
    </form>
  )
}
